import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, useIsPresent } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import '../styles/pages/_about.scss';

const AboutMe = () => {
  const isPresent = useIsPresent();
  
  const data = useStaticQuery(graphql`
    query aboutQuery {
      prismicAboutMe {
        data {
          awards {
            category {
              text
            }
            award {
              text
            }
          }
        }
      }
    }`
  )
  
  return (
    <>
    <Seo title='About Me' />
    <Layout pageId='about-me'>
      <Container>
        <Row>
          <motion.div 
            className='about-me'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ 
              duration: 2, 
              ease: [0.61, 1, 0.88, 1] 
            }}
          >A little about me...</motion.div>
          <motion.div 
            className='col-xs-12 hello' 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 1, 
              delay: 1,
              ease: [0.61, 1, 0.88, 1] 
            }}
          >
            <h1 className='hello__copy'><span className='transparent-50'>I'm</span> <strong>Mark Pacis</strong></h1>
          </motion.div>
          <Col sm={6} xs={12} className='journey'>
            <p>...A <em>digital designer</em> based in Los Angeles.
            My creative journey started at Otis College of Art and Design, and I've been able to work with talented people since my time there. For the <em>past twelve years</em>, I've worked at Palisades Media Group and worked with some marvelous clients*.</p>
          </Col>
          <Col 
            sm={{ span: 5, offset: 1 }} 
            xs={12} 
            className='companies' 
          >
            <p className='companies__copy'>*These are some of the companies I've had a blast working with throughout the past few years.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='ticker'>
            <div className='ticker__inner'>
              <StaticImage
                src='../images/clients.png' 
                className='ticker__image' 
                alt='Past Clients' 
                height={210} 
                placeholder='none' 
                outputPixelDensities='2' 
                pngOptions={{quality: '9'}}
              />
              <StaticImage
                src='../images/clients.png' 
                className='ticker__image' 
                alt='Past Clients' 
                height={210} 
                placeholder='none' 
                outputPixelDensities='2' 
                pngOptions={{quality: '9'}}
              />
            </div>
          </Col>
          <Col xs={12} className='awards'>
            <p>And I've won some gear along the way.</p>
          </Col>
          <Table className='awards__table' bordered={false}>
            <tbody>
              {data.prismicAboutMe.data.awards.map((document, i) => (
                <tr key={i}>
                  <td>
                    {document.award.text}
                  </td>
                  <td>
                    {document.category.text}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col xs={12}>
            <p className='skills'>I usually work in vanilla javascript without frameworks for small projects, which allows me complete freedom on the architecture. I also use a Headless CMS like Prismic or Contentful for content management, GSAP or Framer Motion for animations, and lastly, for more significant projects, I use Gatsby.</p>
          </Col>
          <Col md={4} sm={12} className='skills__categories'>
            <h4 className='skills__category-num'>01/</h4><h1 className='skills__category'>INTERACTION</h1>
            <p className='skills__copy'>A pleasant user experience goes a long way. So, I spend time designing and developing to ensure a smooth and remarkable experience.</p>
          </Col>
          <Col md={4} sm={12} className='skills__categories'>
            <h4 className='skills__category-num'>02/</h4><h1 className='skills__category'>PERFORMANCE</h1>
            <p className='skills__copy'>On the web, every second counts, I pay particular attention to the performance of your project by respecting the best practices of every tool I use.</p>
          </Col>
          <Col md={4} sm={12} className='skills__categories'>
            <h4 className='skills__category-num'>03/</h4><h1 className='skills__category'>TEAMWORK</h1>
            <p className='skills__copy'>I develop your new website by listening to your needs and desires and by giving you my point of view in order to get the best out your project.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h1 className='email__cta'>Let's do something together.</h1>
            <p className='email__link'><a href='mailto:markpacis.creative@gmail.com'>markpacis.creative@gmail.com</a></p>
          </Col>
        </Row>
      </Container>
      <motion.div
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
        exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
        style={{ originY: isPresent ? 0 : 1 }}
        className='screen-page-transition'
      />
    </Layout>
    </>
  )
}

export default AboutMe